<template>
    <div class="configs">
        <nl-loader v-if="loading" :show="true" />
        <div v-else>
            <b-tabs v-model="activeTab">
                <b-tab-item label="Config Générales">
                    <div class="row">
                        <div class="col-sm-6">
                            <InputConfig
                                config-key="blacklist"
                                label="Blacklist"
                                type="textarea"
                                @update="updateData"
                            />
                            <InputConfig
                                config-key="blacklist_adulte"
                                label="Blacklist adulte"
                                type="textarea"
                                @update="updateData"
                            />
                            <InputConfig
                                config-key="liens_externes_autorises"
                                label="Liens externes autorisés (séparés par une virgule)"
                                type="textarea"
                                @update="updateData"
                            />
                            <SelectConfig
                                config-key="categories_adulte"
                                label="Catégories adulte"
                                :multiple="true"
                                @update="updateData"
                            >
                                <option
                                    v-for="category in config.selects.categories"
                                    :key="category.groupe_id"
                                    :value="category.groupe_id"
                                >
                                    {{ category.name }}
                                </option>
                            </SelectConfig>
                            <SelectConfig
                                config-key="prix_promos_active"
                                label="Activer Prix Promo"
                                @update="updateData"
                            >
                                <option value="Oui">Oui</option>
                                <option value="Non">Non</option>
                            </SelectConfig>
                            <InputConfig config-key="rd_min" label="RD Minimums" type="number" @update="updateData" />
                        </div>

                        <div class="col-sm-6">
                            <InputConfig
                                config-key="valeur_bonus_transfert_gain_credit"
                                label="Pourcentage Bonus Transfert Gain/Crédit (ex : 10)"
                                type="number"
                                @update="updateData"
                            />
                            <InputConfig
                                config-key="min_withdrawal"
                                label="Montant de retrait minimum"
                                type="number"
                                @update="updateData"
                            />
                            <InputConfig
                                config-key="max_withdraw"
                                label="Montant de retrait maximum"
                                type="number"
                                @update="updateData"
                            />
                            <InputConfig
                                config-key="commission_precommande_auto"
                                label="Commission précommande auto"
                                type="number"
                                :min="0"
                                :max="100"
                                @update="updateData"
                            />
                            <InputConfig
                                config-key="spot_price"
                                label="Prix d'un spot"
                                type="number"
                                :min="0"
                                :max="100"
                                filter="cents"
                                @update="updateData"
                            />
                            <InputConfig
                                config-key="thematique_price"
                                label="Prix pour les site thématique"
                                type="number"
                                @update="updateData"
                            />
                            <TableConfig config-key="volume_position" @update="updateData">
                                <b-table-column label="Volume/Position">
                                    <template v-slot="props">
                                        {{ props.row.label }}
                                    </template>
                                </b-table-column>
                                <b-table-column label="Top 100">
                                    <template v-slot="props">
                                        <b-input v-model="props.row.top['100']" type="text" name="updateData" />
                                    </template>
                                </b-table-column>
                                <b-table-column label="Top 50">
                                    <template v-slot="props">
                                        <b-input v-model="props.row.top['50']" type="text" />
                                    </template>
                                </b-table-column>
                                <b-table-column label="Top 30">
                                    <template v-slot="props">
                                        <b-input v-model="props.row.top['30']" type="text" />
                                    </template>
                                </b-table-column>
                                <b-table-column label="Top 20">
                                    <template v-slot="props">
                                        <b-input v-model="props.row.top['20']" type="text" />
                                    </template>
                                </b-table-column>
                                <b-table-column label="Top 10">
                                    <template v-slot="props">
                                        <b-input v-model="props.row.top['10']" type="text" />
                                    </template>
                                </b-table-column>
                            </TableConfig>
                            <TableConfig config-key="indice_price" @update="updateData">
                                <b-table-column label="Indice de l'URL">
                                    <template v-slot="props">
                                        {{ props.row.label }}
                                    </template>
                                </b-table-column>
                                <b-table-column label="Sites Bronze">
                                    <template v-slot="props">
                                        <b-input v-model="props.row.bronze" type="text" name="updateData" />
                                    </template>
                                </b-table-column>
                                <b-table-column label="Sites Argent">
                                    <template v-slot="props">
                                        <b-input v-model="props.row.argent" type="text" />
                                    </template>
                                </b-table-column>
                                <b-table-column label="Sites Or">
                                    <template v-slot="props">
                                        <b-input v-model="props.row.or" type="text" />
                                    </template>
                                </b-table-column>
                                <b-table-column label="Sites VIP Bronze">
                                    <template v-slot="props">
                                        <b-input v-model="props.row.vipbronze" type="text" />
                                    </template>
                                </b-table-column>
                                <b-table-column label="Sites VIP Argent">
                                    <template v-slot="props">
                                        <b-input v-model="props.row.vipargent" type="text" />
                                    </template>
                                </b-table-column>
                                <b-table-column label="Sites VIP Or">
                                    <template v-slot="props">
                                        <b-input v-model="props.row.vipor" type="text" />
                                    </template>
                                </b-table-column>
                                <b-table-column label="Média">
                                    <template v-slot="props">
                                        <b-input v-model="props.row.media" type="text" />
                                    </template>
                                </b-table-column>
                            </TableConfig>
                            <TableConfig config-key="coef_top_10" @update="updateData">
                                <b-table-column label="Top 10 sur l'URL">
                                    <template v-slot="props">
                                        {{ props.row.label }}
                                    </template>
                                </b-table-column>
                                <b-table-column label="Coef">
                                    <template v-slot="props">
                                        <b-input v-model="props.row.value" type="text" />
                                    </template>
                                </b-table-column>
                            </TableConfig>
                        </div>
                    </div>
                </b-tab-item>
                <b-tab-item label="Config Accompagnement">
                    <div class="bloc mb-2">
                        <h2>CONSULTANTS</h2>
                    </div>
                    <div class="row">
                        <div class="column">
                            <UserConfig
                                config-key="accompagnement_consultants_offsite"
                                label="Consultants Offsite"
                                @update="updateData"
                            />
                        </div>
                        <div class="column">
                            <UserConfig
                                config-key="accompagnement_consultants_onpage"
                                label="Consultants Onpage"
                                @update="updateData"
                            />
                        </div>
                        <div class="column">
                            <UserConfig
                                config-key="accompagnement_consultants_onsite"
                                label="Consultants Onsite"
                                @update="updateData"
                            />
                        </div>
                    </div>
                    <div class="bloc mb-2">
                        <h2>SUIVI ONSITE</h2>
                    </div>
                    <InputConfig
                        config-key="accompagnement_onsite_followup_tasks"
                        label="Taches préprogrammés (une par ligne)"
                        type="textarea"
                        @update="updateData"
                    />
                    <div class="bloc mb-2">
                        <h2>EMAILS</h2>
                    </div>
                    <h3>Email lancement Accompagnement</h3>
                    <TranslationConfig
                        config-key="email_accompagnement_start_part_one"
                        label="Première partie (présentation) (HTML possible)"
                        type="textarea"
                        @update="updateData"
                    />
                    <TranslationConfig
                        config-key="email_accompagnement_start_part_two"
                        label="Deuxième partie (avant la liste des mots clés) (HTML possible)"
                        type="textarea"
                        @update="updateData"
                    />
                    <TranslationConfig
                        config-key="email_accompagnement_start_part_three"
                        label="Troisième partie (après la liste des mots clés) (HTML possible)"
                        type="textarea"
                        @update="updateData"
                    />
                    <TranslationConfig
                        config-key="email_accompagnement_start_part_four"
                        label="Quatrième partie (après le brief) (HTML possible)"
                        type="textarea"
                        @update="updateData"
                    />
                    <h3>Email Rapport Mensuel Accompagnement</h3>
                    <TranslationConfig
                        config-key="email_accompagnement_monthly_report_part_one"
                        label="Première partie (début de mail) (HTML possible)"
                        type="textarea"
                        @update="updateData"
                    />
                    <TranslationConfig
                        config-key="email_accompagnement_monthly_report_part_two"
                        label="Deuxième partie (fin de mail) (HTML possible)"
                        type="textarea"
                        @update="updateData"
                    />
                    <h3>Email Demande Crédit Accompagnement</h3>
                    <TranslationConfig
                        config-key="email_demande_credit_accompagnement"
                        label="(HTML possible)"
                        type="textarea"
                        @update="updateData"
                    />
                </b-tab-item>
                <b-tab-item label="Config Texte">
                    <div class="bloc mb-2">
                        <h2>INTERFACE</h2>
                    </div>
                    <TranslationConfig
                        type="text"
                        label="Message de maintenance"
                        config-key="message_maintenance"
                        @update="updateData"
                    />
                    <InputConfig
                        field-type="input"
                        type="text"
                        label="Url téléchargement plugin wordpress"
                        config-key="url_download_wordpress_plugin"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Brief Précommandes"
                        config-key="brief_preorders"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Brief Content Spot"
                        config-key="brief_content_spot"
                        @update="updateData"
                    />
                    <div class="bloc mb-2">
                        <h2>PARTENAIRES</h2>
                    </div>
                    <TranslationConfig
                        type="textarea"
                        label="Bloc 'Mes Sites Partenaire' (HTML possible)"
                        config-key="html_site_partenaire"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Onglet 'Mes Sites Désactivés' (HTML possible)"
                        config-key="html_site_bloc_desactive"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Onglet 'Mes Sites Refusés' (HTML possible)"
                        config-key="html_site_bloc_refuse"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Onglet 'Mes Sites Supprimés' (HTML possible)"
                        config-key="html_site_bloc_supprime"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Onglet 'Mes Sites à Améliorer' (HTML possible)"
                        config-key="html_site_bloc_amelioration"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Onglet 'Message Sites - Mes Mots Clés' (HTML possible)"
                        config-key="html_bloc_site_mots_cles"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Onglet 'Mes Commandes' (HTML possible)"
                        config-key="html_bloc_partenaires_commandes"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Onglet 'Mes Commandes En Erreurs' (HTML possible)"
                        config-key="html_bloc_commande_erreur"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Onglet 'Mes Commandes à Traiter' (HTML possible)"
                        config-key="html_bloc_commande_traite"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Onglet 'Mes Commandes à valider' (HTML possible)"
                        config-key="html_bloc_commande_valide"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Onglet 'Gains' (HTML possible)"
                        config-key="html_bloc_partenaires_gains"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Onglet 'Besoins' (HTML possible)"
                        config-key="html_bloc_partenaires_besoins"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Onglet 'Alertes Partenaires' (HTML possible)"
                        config-key="html_bloc_partenaires_alertes"
                        @update="updateData"
                    />
                    <div class="bloc mb-2">
                        <h2>CLIENTS</h2>
                    </div>
                    <TranslationConfig
                        type="textarea"
                        label="Onglet 'Mes Alertes' (HTML possible)"
                        config-key="html_page_alerte"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Onglet 'Précommander' (HTML possible)"
                        config-key="html_bloc_precommandes"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Onglet 'Suivi Précommandes' (HTML possible)"
                        config-key="html_bloc_precommandes_suivi"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Onglet 'Suivi Précommandes' (HTML possible)"
                        config-key="message_accueil"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Onglet 'Commander Lien - Modal' (HTML possible)"
                        config-key="html_bloc_commander_modal"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Onglet 'Favoris' (HTML possible)"
                        config-key="html_bloc_favori"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Onglet 'Mes Crédits' (HTML possible)"
                        config-key="html_bloc_mes_credits"
                        @update="updateData"
                    />
                    <div class="bloc mb-2">
                        <h2>ACCOMPAGNEMENT</h2>
                    </div>
                    <TranslationConfig
                        type="textarea"
                        label="Onglet 'PAIEMENT' (HTML possible)"
                        config-key="html_bloc_paiement_accompagnement"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Onglet 'VALIDATION' (HTML possible)"
                        config-key="html_config_accompagnement_modal"
                        @update="updateData"
                    />
                    <div class="bloc mb-2">
                        <h2>CGV</h2>
                    </div>
                    <!--<TranslationConfig type="textarea" label="Onglet 'CGV' (HTML possible)" config-key="cgv_clients"
                                       @update="updateData"/>
                    <TranslationConfig type="textarea" label="Onglet 'CGV' (HTML possible)" config-key="cgv_partenaires"
                                       @update="updateData"/>-->
                    <TacsConfig label="Onglet 'CGV' (HTML possible)" @update="updateData" />
                </b-tab-item>
                <b-tab-item label="Config Texte Email">
                    <div class="bloc mb-2">
                        <h2>MAILS COMMANDES TERMINÉES AVEC ERREURS</h2>
                    </div>
                    <TranslationConfig
                        type="textarea"
                        label="Code erreur : 500 / 400 / Erreur de communication avec votre site (HTML possible)"
                        config-key="email_erreur_entete_reponse"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Code erreur : Liens externes (HTML possible)"
                        config-key="email_erreur_liens_externes"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Code erreur : Nombre de mots incorrects (HTML possible)"
                        config-key="email_erreur_nb_mots"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Code erreur : Aucun lien vers l’URL (HTML possible)"
                        config-key="email_erreur_lien_url"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Code erreur : Ancre incorrect (HTML possible)"
                        config-key="email_erreur_ancre"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Email inscription (HTML possible)"
                        config-key="email_registration"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Email Gains Atteints Automatique (HTML possible)"
                        config-key="email_auto_gains_atteint"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Email Demande de Retrait (Facture Auto-Générée) (HTML possible)"
                        config-key="email_demande_retrait_facture_nl"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Email Demande de Retrait (Facture à envoyer) (HTML possible)"
                        config-key="email_demande_retrait_facture_partenaire"
                        @update="updateData"
                    />
                    <TranslationConfig
                        type="textarea"
                        label="Email Validation Retrait Gain (HTML possible)"
                        config-key="email_validation_retrait_gainn"
                        @update="updateData"
                    />
                    <div class="bloc mb-2">
                        <h2>SEDINBLUE > CONFIGURATION LISTES</h2>
                    </div>
                    <InputConfig
                        config-key="sendinblue_list_general"
                        label="ID de la liste global"
                        type="number"
                        @update="updateData"
                    />
                    <InputConfig
                        config-key="sendinblue_list_prospect"
                        label="ID de la liste pour le role PROSPECT"
                        type="number"
                        @update="updateData"
                    />
                    <InputConfig
                        config-key="sendinblue_list_partenaire"
                        label="ID de la liste pour le role PARTENAIRE"
                        type="number"
                        @update="updateData"
                    />
                    <InputConfig
                        config-key="sendinblue_list_agence"
                        label="ID de la liste pour le role AGENCE"
                        type="number"
                        @update="updateData"
                    />
                </b-tab-item>
                <b-tab-item label="Config Recherche">
                    <div class="bloc mb-2">
                        <h2>ANCRES GÉNÉRIQUES</h2>
                    </div>
                    <InputConfig
                        config-key="ancres_generiques"
                        label="Ancres génériques"
                        type="textarea"
                        @update="updateData"
                    />
                </b-tab-item>
            </b-tabs>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import TableConfig from '../../components/Admin/Config/TableConfig'
    import SelectConfig from '../../components/Admin/Config/SelectConfig'
    import InputConfig from '../../components/Admin/Config/InputConfig'
    import TranslationConfig from '../../components/Admin/Config/TranslationConfig'
    import UserConfig from '../../components/Admin/Config/UserConfig'
    import TacsConfig from '../../components/Admin/Config/TacsConfig'

    export default {
        name: 'Config',
        title: 'Config',
        components: {
            TacsConfig,
            UserConfig,
            TranslationConfig,
            InputConfig,
            SelectConfig,
            TableConfig
        },
        data() {
            return {
                activeTab: 0,
                loading: true,
                columnIndex: 0,
                callbackFunction: null
            }
        },
        computed: {
            ...mapState('config', {
                config: state => state.config,
                admins: state => state.admins
            })
        },
        created() {
            this.getConfigs()
                .catch(e => {
                    this.$store.dispatch('toastError', e)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        methods: {
            ...mapActions('config', ['getConfigs', 'updateConfigs']),
            updateData(data) {
                this.updateConfigs(data)
                    .then(() => {
                        this.$store.dispatch('toast', {
                            type: 'success',
                            message: 'Modification effectué avec succès !',
                            title: 'Modification'
                        })
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            showModal(data) {
                this.$refs.assignModal.show()
                this.columnName = data.key
                this.callbackFunction = data.callback
            }
        }
    }
</script>